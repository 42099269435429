import React, { useEffect, useState, useMemo, useContext } from "react";
import _ from "lodash";
import { Container, Row, Col, Spinner, Table } from "react-bootstrap";
import { FaCircle, FaCopy, FaDotCircle } from "react-icons/fa";
import { doGET } from "../../../../../util/HttpUtil";
import { DialogContext } from "../../../../../store/context/DialogContext";
import "./style.css"
import clipboardCopy from "clipboard-copy";

const stepStatusOptions = [
    { label: "Pending", value: 0, backgroundColor: "#F8D76E", keyColor: "#CD9941", },
    { label: "Completed", value: 1, backgroundColor: "#B2D8B2", keyColor: "#3D5941" },
    { label: "Pending", value: 2, backgroundColor: "#F8D76E", keyColor: "#CD9941", },
    { label: "Completed", value: 3, backgroundColor: "#B2D8B2", keyColor: "#3D5941" },
    { label: "Rejected", value: 4, backgroundColor: "#F1A7A6", keyColor: "#8B4A4A" },
    { label: "Completed", value: 5, backgroundColor: "#B2D8B2", keyColor: "#3D5941" },
];

const mapFlowStatusToLabel = (flowStatusValue) => {
    const found = (stepStatusOptions ?? [])?.find((o) => o?.value === flowStatusValue);
    return found ? found :  { label: "Pending", value: 0, backgroundColor: "#F8D76E", keyColor: "#4A4A4A", };
};

const BusinessPlusKYCStatus = ({ applicationId }) => {
    const [loading, setLoading] = useState(true);
    const [uboData, setUboData] = useState([]);
    const [ondatoData, setOndatoData] = useState([]);
    const { showError, showMessage } = useContext(DialogContext);
    const combinedUBO = useMemo(() => {
        const ondatoGrouped = _.groupBy(ondatoData, "ubo_id");

        return (uboData ?? [])?.map((ubo) => {
            const reports = ondatoGrouped[ubo?._id] || [];
            const latest = _.maxBy(reports, (r) => r?.seq);
            const kycStatus = latest
                ? mapFlowStatusToLabel(latest?.flowStatus)
                : { label: "Pending", value: 0, backgroundColor: "#F8D76E", keyColor: "#4A4A4A", };

            return {
                ...ubo,
                finalKycStatus: kycStatus,
                latestOndato: latest
            };
        });
    }, [uboData, ondatoData]);

    useEffect(() => {
        const fetchUBO = async () => {
            try {
                const res = await doGET(
                    `/api/ubo/grid?application_id=${applicationId}&rows=-1`
                );
                if (res?.status === 200) {
                    const uboData = res?.data?.rows?.filter(individual => {
                        if (individual?.isShareHolder) {
                            return parseFloat(individual?.percentOwner) >= 10
                        } else {
                            return individual?.isAuthorizedUser
                        }
                    })
                    setUboData(uboData || []);
                }
            } catch (err) {
                console.error("Failed to fetch UBO data:", err);
            }
        };

        const fetchOndato = async () => {
            try {
                const res = await doGET(
                    `/api/ondato/grid?form_id=${applicationId}&sortBy=seq&sortAsc=false&rows=-1`
                );
                if (res?.status === 200) {
                    setOndatoData(res?.data?.rows || []);
                }
            } catch (err) {
                console.error("Failed to fetch Ondato data:", err);
            }
        };

        const loadAll = async () => {
            setLoading(true);
            await Promise.all([fetchUBO(), fetchOndato()]);
            setLoading(false);
        };

        if (applicationId) {
            loadAll();
        }
    }, [applicationId]);

    // Build the link for each UBO
    const generateKycLink = (ubo) => {
        const currentHost = window.location.hostname;
        const applyHost = _.toLower(currentHost) || "apply.fvbank.us";
        const { _id: uboId, email } = ubo;

        return `https://${applyHost}/verify-individual/?form_id=${applicationId}&ubo_id=${uboId}&email=${email}&formType=BUSINESS_PLUS`;
    };

    const handleCopyLink = (link) => {
        clipboardCopy(link);
        showMessage('Link copied to clipboard!');
    };

    if (loading) {
        return (
            <div
                className="d-flex justify-content-center align-items-center fv_bplus_operators_loader"
                style={{ minHeight: 200 }}
            >
                <Spinner animation="border" />
            </div>
        );
    }

    if (!combinedUBO?.length) {
        return <p className="fv_bplus_operators_noData">No operator/shareholder data found.</p>;
    }

    return (
        <Container fluid className="fv_bplus_operators_wrapper py-4">
            <Row>
                <Col>
                    <h2 className="fv_bplus_operators_title mb-4">
                        Operator/Shareholder KYC Status
                    </h2>
                    <Table
                        bordered
                        hover
                        responsive
                        className="fv_bplus_operators_table shadow-sm"
                    >
                        <thead>
                            <tr className="fv_bplus_operators_table_head">
                                <th className="fv_bplus_operators_table_th">Name</th>
                                <th className="fv_bplus_operators_table_th">Email</th>
                                <th className="fv_bplus_operators_table_th">KYC Status</th>
                                <th className="fv_bplus_operators_table_th">Link to complete KYC</th>
                            </tr>
                        </thead>
                        <tbody>
                            {(combinedUBO ?? [])?.map((ubo) => {
                                const link = generateKycLink(ubo);
                                return (
                                    <tr key={ubo?._id} className="fv_bplus_operators_table_tr">
                                        <td className="fv_bplus_operators_table_td">
                                            {ubo?.firstName} {ubo?.lastName}
                                        </td>
                                        <td className="fv_bplus_operators_table_td">{ubo?.email}</td>
                                        <td  style={{color : ubo?.finalKycStatus?.keyColor}} className="fv_bplus_operators_table_td">
                                            <FaCircle style={{ color: ubo?.finalKycStatus?.backgroundColor }}/>    {ubo?.finalKycStatus?.label ?? "Pending"}
                                        </td>
                                        <td className="fv_bplus_operators_table_td">
                                            <div className="d-flex align-items-center">
                                                <a
                                                    href={link}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    className="fv_bplus_operators_link me-2"
                                                >
                                                    Open Link 
                                                </a>
                                                <FaCopy
                                                    className="fv_bplus_operators_copyIcon"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => handleCopyLink(link)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    );
};

export default BusinessPlusKYCStatus;
